@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

*{
  box-sizing: border-box;
}
body, html {-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;}

body{
  overflow-x: hidden;
}
body, html {-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;}
  
body, html{
  @include media('<=tablet'){
    width: 100%;
    height: 100%;
  }
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
}

body, html, ul, ol{
  padding: 0;
  margin: 0;
}

button{
  padding: 0;
  outline: 0;
}
main.site {
    overflow-x: hidden !important;
}

a{
  text-decoration: none;
  cursor: pointer;
}

.wpcf7-file {
  margin-bottom: 100px;
}

.center{
  max-width: 1280px;
  margin: 0 auto;
  @include media('<=1420px'){
    padding: 0 50px;
  }
  @include media('<=980px'){
    padding: 0 20px;
  }
}

// Alpha color
@function alpha($color: $black, $opacity: 0.5) { 
  $alpha-color-value: rgba($color, $opacity); 
  @return $alpha-color-value;
}

// wordpress default aligment

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}