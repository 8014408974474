.siteHeader {
  background: $darkBlue;
  height: 120px;
  &__Inner {
    display: flex;
    justify-content: space-between;
    height: 120px;
    position: relative;
  }
}
.siteHeaderLogo {
  position: relative;
  width: 21%;
  display: block;
  z-index: 10000000000;
  a {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    height: 75px;
    right: 0;
    width: 1px;
    background: $white;
    opacity: 0.2;
  }
  svg {
    max-width: 90%;
    vertical-align: middle;
  }
}
.siteHeaderNav {
  text-align: left;
  width: 64%;
  z-index: 1;
  nav {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    ul {
      position: absolute;
      top: 0;
      transform: translateY(-500px);
      padding-top: 60px;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: opacity 0.4s;
      -moz-transition: opacity 0.4s;
      -o-transition: opacity 0.4s;
      transition: opacity 0.4s;
      text-align: left;
      column-count:2;
      column-gap: 50px;
      li {
        display: block;
        margin-bottom: 20px;
      }
      &:before {
        content: '';
        position: absolute;
        left: -100vw;
        right: -100vw;
        top: 60px;
        min-height: 270px;
        background: $white;
        z-index: -1;
      }
    }
    &:hover {
      ul {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  a {
    font-size: 12.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: $white;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    z-index: 1000;
    &:before {
      content: '';
      position: absolute;
      left: 5px;
      bottom: -5px;
      height: 2px;
      width: 0px;
      background: $white;
      transition:300ms;
    }
    /*&:after {
      content: attr(title);
      position: absolute;
      top: -50px;
      left: -200px;
      transform:rotate(-90deg);
      bottom: 0;
      right: 0;
      color: $gray2;
      text-transform: initial;
    }*/
    &:hover {
      &:before {
        width: 15px;
      }
    }
  }
  li.menuButton {
      a {
        background: $white;
        color: $blue;
        padding: 7.5px;
        transition:300ms;
        &::before {
          display: none !important;
        }
        &:hover {
          opacity: 0.9;
        }
      }
  }
}
.siteHeaderLang {
  text-align: right;
  width: 10%;
  ul{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
  }
  a {
    font-size: 12.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: $white;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;
  }
  span {
    font-size: 12.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: $white;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
  }
  .socialNetwork {
    a {
      font-size: 14px;
    }
  }
}
.siteHeaderWhite {
  background: $white;
  position: relative;
  z-index: 100000000;
  &:after {
    content:'';
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: 0;
    top: 0;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
    z-index: -1;
  }
  .siteHeaderLogo {
    &:before {
      background: $gray;
    }
  }
  .siteHeaderNav {
    a {
      color: $blue;
      &:before {
        background: $blue;
      }
    }
    li.menuButton {
        a {
          background: $blue;
          color: $white;
          padding: 7.5px;
          transition:300ms;
          &::before {
            display: none !important;
          }
          &:hover {
            opacity: 0.9;
          }
        }
    }
  }
  .siteHeaderLang {
    a {
      color: $blue;
    }
    span {
      color: $blue;
    }
  }
}
.searchBlock {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: alpha($blue, 0.95);
  z-index: 10000000000000;
  height: 100vh;
  transform:translateY(-100%);
  opacity: 0;
  transition: 300ms;
  &--active {
    transform: translateY(0);
    opacity: 1;
  }
  .searchCloseButton {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    i {
      font-size: 40px;
      color: $white;
    }
  }
  input[type="text"] {
    background: transparent;
    border: 0;
    border-bottom: 2px solid $white;
    width: 70%;
    height: 80px;
    line-height: 78px;
    font-family: $default;
    font-size: 28px;
    color: $white;
    @include media('<=841px'){
      width: 100%;
      margin-bottom: 20px;
    }
  }
  ::-webkit-input-placeholder { /* Edge */
    color: $white;
    opacity: 1;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $white;
    opacity: 1;
  }
  
  ::placeholder {
    color: $white;
    opacity: 1;
  }
  input[type="submit"] {
    width: 25%;
    height: 80px;
    line-height: 78px;
    border-radius: 0;
    border: 0;
    background: $white;
    font-family: $default;
    font-size: 28px;
    color: $blue;
    cursor: pointer;
    @include media('<=841px'){
      width: 100%;
    }
  }
}
.searchInner {
  max-width: 840px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  @include media('<=841px'){
    max-width: 90%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include media('<=841px'){
      flex-wrap: wrap;
    }
  }
}