.tableData {
  width: 100%;
  margin-bottom: 100px;

  table {
    width: 100%;
    font-size: 18.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.84;
    letter-spacing: 0.9px;
    border: none;
  }

  thead {
    background: $white;
    color: $blue;
    text-align: left;
    height: 60px;

    th {
      padding-left: 80px;
    }
  }

  tfoot {
    background: $white;
    color: $blue;
    text-align: left;
    height: 60px;
    text-transform: uppercase;

    td {
      padding-left: 80px;
    }
  }

  tbody {
    tr {
      height: 60px;
      color: $white;

      &:nth-of-type(even) {
        background: $blue;
      }

      &:nth-of-type(odd) {
        background: $darkBlue;
      }
    }

    td {
      padding-left: 80px;
      border-bottom: 1px solid $white;
    }
  }
}

.partnersBlock {
  margin-bottom: 100px;

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
  }

  h4 {
    max-width: 460px;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $darkGray3;
  }

  table {
    width: 100%;
    font-size: 18.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.84;
    letter-spacing: 0.9px;
    border: none;
  }

  tbody {
    tr {
      height: 100px;
    }

    td {
      border-bottom: 1px solid $darkGray2;
      text-align: center;
    }

    a {
      opacity: 0.6;
      transition: 300ms;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.infoBlock {

  /*margin-bottom: 100px;*/
  h2 {
    margin: 0;
    padding-left: 60px;
    margin-bottom: 60px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
  }

  ul {
    padding: 0 60px;
    margin: 0;
    margin-bottom: 60px;
    list-style: none;
    width: 50%;
  }

  li {
    font-size: 21.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.72;
    letter-spacing: 1.1px;
    text-align: left;
    color: $darkGray;
    display: block;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid $darkGray;
  }
}

.infoBlockWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.formBlock {
  margin-bottom: 100px;

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
  }

  h4 {
    max-width: 460px;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $darkGray3;
  }

  form {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: $default;
  }

  form p {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: $blue;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $blue;
    opacity: 1;
  }

  ::placeholder {
    color: $blue;
    opacity: 1;
  }

  input[type="text"],
  input[type="email"] {
    width: 100%;
    margin-bottom: 100px;
    padding-bottom: 20px;
    border: 0;
    border-bottom: 2px solid $blue;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    text-align: left;
    letter-spacing: 0.6px;
    padding-left: 20px;
    font-family: $default;
    color: $blue;
  }

  select {
    width: 100%;
    margin-bottom: 100px;
    padding-bottom: 20px;
    border: 0;
    border-bottom: 2px solid $blue;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    text-align: left;
    letter-spacing: 0.6px;
    padding-left: 20px;
    font-family: $default;
    color: $blue;
  }

  .wpcf7-form-control-wrap {
    width: 40%;
  }

  .wpcf7-form-control-wrap.your-message {
    width: 93.5%;
  }

  .wpcf7-form-control-wrap.Usluga {
    width: 93.5%;
  }

  textarea {
    width: 100%;
    border: 0;
    border-bottom: 2px solid $blue;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    text-align: left;
    letter-spacing: 0.6px;
    padding-left: 20px;
    font-family: $default;
    margin-bottom: 100px;
    color: $blue;
  }

  label.acceptance {
    width: 73%;
    position: relative;
    background: $lightGray;
    height: 45px;
    line-height: 45px;
    border-radius: 23px;

    input[type="checkbox"] {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      position: absolute;
    }

    .wpcf7-form-control-wrap {
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
      line-height: 0 !important;
      overflow: hidden !important;
      position: absolute !important;
    }

    span.inner-acceptance {
      padding-left: 57px;
      font-size: 13.5px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: 0.7px;
      height: 45px;
      line-height: 45px;
      color: $darkGray3;
      font-family: $default;
      display: block;
      width: 100%;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 31px;
        width: 31px;
        left: 0;
        top: 0px;
        border: 7px solid $darkGray3;
        border-radius: 100%;
      }
    }
  }

  .inner-acceptance-active {
    &:before {
      border: 0 !important;
      height: 45px !important;
      width: 45px !important;
      background-image: url('../images/checked.svg');
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  input[type="submit"] {
    width: 13%;
    border-radius: 23px;
    background: $blue;
    border-color: transparent;
    font-size: 14.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 0.7px;
    text-align: left;
    color: $white;
    text-transform: uppercase;
    font-family: $default;
    padding-left: 35px;
    background-image: url('../images/right-arrow.svg');
    background-position: 80% center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    cursor: pointer;
    transition: 300ms;

    &:hover {
      transform: scale(1.05)
    }
  }

  .wpcf7-not-valid-tip {
    display: none !important;
  }

  .wpcf7-not-valid {
    border-bottom: 2px solid #f00 !important;
  }

  .wpcf7-response-output {
    width: 80%;
    text-align: center;
    padding: 20px !important;
    font-weight: lighter !important;
    border-width: 1px !important;
    margin-left: 10% !important;
  }
}


.quickFormBlock {
  margin-bottom: 100px;
  background-image: url('../images/quick-call.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto;

  .wpcf7-not-valid-tip {
    display: none !important;
  }

  .wpcf7-not-valid {
    border-bottom: 2px solid #f00 !important;
  }

  .wpcf7-response-output {
    width: 80%;
    text-align: center;
    padding: 20px !important;
    font-weight: lighter !important;
    border-width: 1px !important;
    margin-left: 10% !important;
  }

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
  }

  h4 {
    max-width: 460px;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $darkGray3;
  }

  form {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: $default;
  }

  form p {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: $blue;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $blue;
    opacity: 1;
  }

  ::placeholder {
    color: $blue;
    opacity: 1;
  }

  input[type="text"],
  input[type="email"] {
    width: 100%;
    margin-bottom: 100px;
    padding-bottom: 20px;
    border: 0;
    border-bottom: 2px solid $blue;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    text-align: left;
    letter-spacing: 0.6px;
    padding-left: 20px;
    font-family: $default;
  }

  .wpcf7-form-control-wrap {
    width: 40%;
  }

  textarea {
    width: 100%;
    border: 0;
    border-bottom: 2px solid $blue;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    text-align: left;
    letter-spacing: 0.6px;
    padding-left: 20px;
    font-family: $default;
    margin-bottom: 100px;
  }

  label.acceptance {
    width: 73%;
    position: relative;
    background: $lightGray;
    height: 45px;
    line-height: 45px;
    border-radius: 23px;

    input[type="checkbox"] {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      position: absolute;
    }

    .wpcf7-form-control-wrap {
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
      line-height: 0 !important;
      overflow: hidden !important;
      position: absolute !important;
    }

    span.inner-acceptance {
      padding-left: 57px;
      font-size: 13.5px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: 0.7px;
      height: 45px;
      line-height: 45px;
      color: $darkGray3;
      font-family: $default;
      display: block;
      width: 100%;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 31px;
        width: 31px;
        left: 0;
        top: 0px;
        border: 7px solid $darkGray3;
        border-radius: 100%;
      }
    }
  }

  .inner-acceptance-active {
    &:before {
      border: 0 !important;
      height: 45px !important;
      width: 45px !important;
      background-image: url('../images/checked.svg');
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  input[type="submit"] {
    width: 13%;
    border-radius: 23px;
    background: $blue;
    border-color: transparent;
    font-size: 14.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 0.7px;
    text-align: left;
    color: $white;
    text-transform: uppercase;
    font-family: $default;
    padding-left: 35px;
    background-image: url('../images/right-arrow.svg');
    background-position: 80% center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    cursor: pointer;
    transition: 300ms;

    &:hover {
      transform: scale(1.05)
    }
  }
}


.newsletter {
  margin-bottom: 100px;
  background-color: $blue;
  background-image: url('../images/newsletter-bg.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 100%;
  height: 220px;

  .center {
    display: flex;
    justify-content: space-between
  }

  h2 {
    margin: 0;
    margin-top: 30px;
    font-size: 42.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: initial;
    letter-spacing: 2.1px;
    text-align: left;
    color: $white;
  }

  h4 {
    font-size: 21.3px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: initial;
    letter-spacing: 0.9px;
    text-align: left;
    color: $white;
  }

  form {
    top: 50%;
    transform: translateY(-30%);
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: $default;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: $white;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $white;
    opacity: 1;
  }

  ::placeholder {
    color: $white;
    opacity: 1;
  }

  input[type="text"],
  input[type="email"] {
    width: 85%;
    border: 0;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: 0.2px;
    text-align: left;
    padding-left: 70px;
    font-family: $default;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    background: $darkBlue;
    color: $white;
    height: 70px;
    line-height: 70px;
  }

  input[type="submit"] {
    width: 20%;
    margin-left: -5%;
    border-radius: 35px;
    background: $white;
    border-color: transparent;
    font-size: 14.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.7px;
    color: $blue;
    text-transform: uppercase;
    font-family: $default;
    cursor: pointer;
    transition: 300ms;

    &:hover {
      transform: scale(1.05)
    }
  }
}

.newsletterLeft {
  width: 35%;
}

.newsletterRight {
  width: 60%;
  height: 220px;
}

.postHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 1.9px;
    text-align: left;
    color: $white;
    margin: 0;
  }

  h3 {
    font-size: 26.5px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.81;
    letter-spacing: 1.3px;
    text-align: left;
    color: $white;
    margin: 0 0 40px 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.81;
    letter-spacing: 0.5px;
    text-align: left;
    color: $darkGray3;
  }

  a,
  span {
    font-weight: bold;
    color: $blue;
  }
}

.postHeaderLeft {
  width: 45%;
  height: 340px;
  background: $blue;
  padding-left: calc(50% - 640px);
  position: relative;
}

.postHeaderLeftInner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 70px;
}

.postHeaderRight {
  width: 55%;
  padding-left: 80px;
}

.postHeaderRightInner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.site>.postContent {
  padding-bottom: 0;
}

.site>.postContent~.postContent {
  padding-bottom: 100px;
}

.postContent {
  width: 100%;
  padding: 100px 0;

  /*margin-bottom: 100px;*/
  .center {
    max-width: 920px;
  }

  p,
  li {
    font-size: 19px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: 0.6px;
    color: $darkGray3;
  }

  ul,
  ol {
    padding: 0 30px;
  }

  a {
    color: $blue;
  }

  h3 {
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: 0.6px;
    font-size: 24.5px;
    letter-spacing: 0.7px;
    color: $blue;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}


.postHeaderColor {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: $darkBlue;

  h2 {
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 1.9px;
    text-align: left;
    color: $white;
    margin: 0;
  }

  h3 {
    font-size: 26.5px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.81;
    letter-spacing: 1.3px;
    text-align: left;
    color: $white;
    margin: 0 0 40px 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.81;
    letter-spacing: 0.5px;
    text-align: left;
    color: $white;
  }

  a,
  span {
    font-weight: bold;
    color: $white;
  }

  .postHeaderRight {
    background-image: url('../images/color-post-bg.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center left;
  }
}


.postContentColor {
  width: 100%;
  /*margin-bottom: 100px;*/
  background: $darkBlue;
  padding: 100px 0;

  .center {
    max-width: 920px;
  }

  p,
  li {
    font-size: 19px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: 0.6px;
    color: $white;
  }

  ul,
  ol {
    padding: 0 30px;
  }

  a {
    color: $blue;
  }

  h3 {
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: 0.6px;
    font-size: 24.5px;
    letter-spacing: 0.7px;
    color: $yellow;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}





.pageHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &--margin {
    margin-bottom: 100px;
  }

  h2 {
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 1.9px;
    text-align: left;
    color: $white;
    margin: 0;
  }

  h3 {
    font-size: 24.5px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.81;
    letter-spacing: 1.3px;
    text-align: left;
    color: $white;
    margin: 0 0 40px 0;

    @include media('<=1200px') {
      font-size: 19px;
    }

    @include media('<=640px') {
      font-size: 16px;
    }
  }
}

.pageHeaderLeft {
  width: 45%;
  height: 340px;
  background: $blue;
  padding-left: calc(50% - 640px);
  position: relative;
}

.pageHeaderLeftInner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.pageHeaderRight {
  width: 55%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('../images/color-page-bg.png');
    background-position: center left;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
}

.pageHeaderSmall {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  display: block;
  width: 270px;
  height: 230px;
  overflow: hidden;
  top: 135px;
  left: -22px;

  img {
    position: absolute;
    height: 230px;
    left: 0;
    object-fit: cover;
    mask-image: url('../images/cover-small-bg.png');
  }

  .coverPhoto {
    opacity: 0.4;
  }
}

.pageContent {
  width: 100%;
  padding: 100px 0;

  .center {
    padding-right: 320px;
  }

  p,
  li {
    font-size: 19px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: 0.6px;
    color: $darkGray3;
  }

  ul,
  ol {
    padding: 0 30px;
  }

  a {
    color: $blue;
  }

  h3 {
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: 0.6px;
    font-size: 24.5px;
    letter-spacing: 0.7px;
    color: $blue;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.topSlider {
  height: 570px;
  position: relative;
  margin-bottom: 100px;

  .js-topSlider {
    height: 570px !important;
  }

  .button {
    position: absolute;
    bottom: 60px;
    right: 0;
    z-index: 1000;

    svg {
      height: 20px;

      polygon {
        fill: $white;
      }
    }

    &--next {
      right: 0px;
      transform: rotate(-90deg);
    }

    &--previous {
      right: 30px;
      transform: rotate(90deg);
    }
  }

  .slick-list {
    overflow: visible;
  }
}

.topSliderSlide {
  height: 550px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(45deg, $blue 30%, rgba(125, 185, 232, 0) 60%);
    background-color: alpha($blue, 0.6);
  }
}

.topSliderContent {
  position: absolute;
  bottom: -50px;
  left: 358px;
  max-width: 430px;
  min-height: 350px;
  background-image: url('../images/slider-bg.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 100px;
  z-index: 10000000;

  a {
    font-size: 14.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.7px;
    text-align: left;
    color: $yellow;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;

      path {
        fill: $yellow;
      }
    }

    span {
      margin-top: 1px;
    }
  }

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $white;
  }

  p {
    font-size: 18.7px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $white;
    margin-bottom: 30px;
  }
}

.overlayBg {
  position: absolute;
  top: 0;
  left: 0;
  height: 550px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    left: 130px;
    top: 50px;

    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: -40px;
      height: 270px;
      width: 25px;
      background-image: url('../images/arrow-down.png');
      background-repeat: no-repeat;
      background-position: top center;
      background-size: auto 100%;
    }

    li {
      a {
        font-size: 17.9px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.79;
        letter-spacing: 0.9px;
        text-align: left;
        color: $gray2;
        transition: 300ms;

        &:hover {
          color: $blue;
        }
      }

      a.activeMenuItem {
        color: $blue;
      }
    }
  }

  .blueMenu {
    &:before {
      background-image: url('../images/arrow-down-white.png');
    }

    li {
      a {
        color: $white;

        &:hover {
          color: $white;
          opacity: 0.8;
        }
      }
    }
  }
}


.blueMenuHide {
  overflow: hidden;
}

.ctaWhite {
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
    margin: 0 0 30px 0;
  }

  h4 {
    font-size: 21.3px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: 0.9px;
    text-align: left;
    color: $darkGray3;
  }
}

.ctaWhiteLeft {
  width: 50%;
  background: $white;
  padding-left: calc(50% - 640px);
  padding-right: 100px;
  position: relative;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: 30px;
    bottom: 30px;
    right: 30px;
    width: 1px;
    background: $gray;
  }
}

.ctaWhiteLeftInner {
  position: relative;
  padding: 30px 0 30px 30px;
}

.ctaWhiteRight {
  width: 50%;
  position: relative;
  z-index: 1;

  img {
    position: absolute;
    left: -140px;
    bottom: -80px;
    max-height: 380px;
  }
}






.ctaBlue {
  width: 100%;
  margin-bottom: 200px;
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $white;
    margin: 0 0 30px 0;
  }

  h4 {
    font-size: 21.3px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: 0.9px;
    text-align: left;
    color: $white;
  }
}

.ctaBlueRight {
  width: 50%;
  background: $blue;
  background-image: url('../images/cta-3-bg.png');
  background-position: bottom right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding-right: calc(50% - 640px);
  padding-left: 100px;
  position: relative;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 30px;
    width: 1px;
    background: $white;
  }

  .moreButton {
    position: absolute;
    right: 10%;
    bottom: -100px;

    a {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      transition: 300ms;

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }
    }

    .label {
      font-size: 14.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.07;
      letter-spacing: 0.7px;
      text-align: left;
      color: $blue;
      display: inline-block;
      margin-right: 40px;
      margin-top: 5px;
    }

    .arrow {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: $blue;
      display: inline-block;

      svg {
        width: 20px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
          fill: $white;
        }
      }
    }
  }
}

.ctaBlueRightInner {
  position: relative;
  padding: 30px 0 30px 30px;
}

.ctaBlueLeft {
  width: 50%;
  position: relative;
  z-index: 1;

  img {
    position: absolute;
    right: -140px;
    bottom: -80px;
    max-height: 380px;
  }
}

.ctaFull {
  width: 100%;
  height: 220px;
  background: $blue;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  h2 {
    font-size: 42.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.98;
    letter-spacing: 2.1px;
    text-align: left;
    color: $white;
    margin: 0;
    margin-bottom: 20px;
  }

  p {
    font-size: 21.3px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: 0.9px;
    text-align: left;
    color: $white;
    max-width: 700px;
  }
}

.ctaFullLeft {
  height: 220px;
  width: 65%;
  padding-left: calc(50% - 640px);
  background-image: url('../images/cta-4-bg.png');
  background-position: center right;
  background-repeat: no-repeat;

  &--text {
    a {
      transition: 300ms;
      display: block;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.ctaFullLeftInner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.ctaFullRight {
  background: $darkBlue;
  width: 35%;
  padding-right: calc(50% - 640px);
  height: 220px;

  a {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    transition: 300ms;

    &:hover {
      transform: translateY(-50%) scale(1.05);
    }
  }

  &--text {
    a {
      display: block;
    }

    h2,
    p {
      text-align: right;
    }
  }

  .label {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: 1.2px;
    text-align: left;
    color: $white;
    display: inline-block;
    margin-right: 60px;
    margin-top: 5px;
  }

  .arrow {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: $white;
    display: inline-block;

    svg {
      width: 30px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: $blue;
      }
    }
  }
}

.infoMapBlock {
  margin-bottom: 100px;

  .center {
    display: flex;
    justify-content: space-between
  }

  h2 {
    margin: 0;
    margin-bottom: 60px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    font-size: 21.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.1px;
    text-align: left;
    color: $darkGray;
    display: block;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid $darkGray;

    a {
      color: $darkGray2;
    }
  }
}

.infoMapBlockLeft,
.infoMapBlockRight {
  width: 48%;
}

.infoMapBlockLeft {
  padding: 60px;
}



.whiteSlider {
  height: 570px;
  position: relative;
  margin-bottom: 100px;

  .js-whiteSlider {
    height: 570px !important;
  }

  .button {
    position: absolute;
    bottom: 60px;
    right: 0;
    z-index: 1000;

    svg {
      height: 20px;

      polygon {
        fill: $white;
      }
    }

    &--next {
      right: 0px;
      transform: rotate(-90deg);
    }

    &--previous {
      right: 30px;
      transform: rotate(90deg);
    }
  }

  .slick-list {
    overflow: visible;
  }
}

.whiteSliderSlide {
  height: 550px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.whiteSliderContent {
  position: absolute;
  bottom: -34px;
  left: 360px;
  width: 100%;
  max-width: 720px;
  min-height: 490px;
  background-image: url('../images/slider-bg-white.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 130px 280px 0 130px;
  z-index: 10000000;

  a {
    font-size: 14.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.7px;
    text-align: left;
    color: $blue;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
      margin-top: -2px;
      margin-right: 15px;

      path {
        fill: $blue;
      }
    }

    span {
      margin-top: 1px;
    }
  }

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
  }

  p {
    font-size: 18.7px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $blue;
    margin-bottom: 30px;
  }
}

.blueSlider {
  height: 570px;
  position: relative;
  margin-bottom: 100px;

  .js-blueSlider {
    height: 570px !important;
  }

  .overlayBg {
    overflow: hidden;
  }

  .button {
    position: absolute;
    bottom: 60px;
    right: 0;
    z-index: 1000;

    svg {
      height: 20px;

      polygon {
        fill: $white;
      }
    }

    &--next {
      right: 0px;
      transform: rotate(-90deg);
    }

    &--previous {
      right: 30px;
      transform: rotate(90deg);
    }
  }

  .slick-list {
    overflow: visible;
  }
}

.blueSliderSlide {
  height: 550px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(45deg, $blue 30%, rgba(125, 185, 232, 0) 60%);
    /*background-color: alpha($blue, 0.6);*/
  }
}

.blueSliderContent {
  position: absolute;
  bottom: -72px;
  left: 359px;
  max-width: 560px;
  min-height: 350px;
  background-image: url('../images/slider-bg-blue.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 100px;
  z-index: 10000000;

  a {
    font-size: 14.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.7px;
    text-align: left;
    color: $yellow;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;

      path {
        fill: $yellow;
      }
    }

    span {
      margin-top: 1px;
    }
  }

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $white;
    /*padding-right: 150px;*/
  }

  p {
    font-size: 18.7px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $white;
    margin-bottom: 30px;
    padding-right: 50px;
  }
}


.sliderOne {
  height: 570px;
  position: relative;
  margin-bottom: 100px;

  .js-sliderOne {
    height: 570px !important;
  }

  .button {
    position: absolute;
    bottom: auto;
    right: auto;
    left: 50%;
    top: 375px;
    z-index: 1000;
    background: $blue;
    width: 50px;
    height: 50px;

    svg {
      height: 20px;
      display: block;
      width: 100% !important;
      top: 50%;
      position: relative;
      transform: translateY(-50%);

      polygon {
        fill: $white;
      }
    }

    &--next {
      right: auto;
      left: calc(60% - 50px);
      transform: rotate(-90deg);
    }

    &--previous {
      right: auto;
      left: calc(60% - 100px);
      transform: rotate(90deg);
      background: $darkBlue;
    }
  }

  .slick-list {
    overflow: visible;
  }
}

.sliderOneSlide {
  height: 550px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.sliderOneContent {
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  width: 60%;
  padding: 100px;
  padding-left: calc(50% - 640px);
  padding-right: 200px;
  background: alpha($white, 0.95);
  z-index: 10000000;

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
  }

  p {
    font-size: 18.7px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $darkGray3;
  }

  a {
    font-size: 14.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.7px;
    text-align: left;
    color: $blue;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;

      path {
        fill: $blue;
      }
    }

    span {
      margin-top: 1px;
    }
  }
}



.sliderTwo {
  height: 570px;
  position: relative;
  margin-bottom: 100px;

  .js-sliderTwo {
    height: 570px !important;
  }

  .button {
    position: absolute;
    bottom: auto;
    right: auto;
    left: 50%;
    top: 375px;
    z-index: 1000;
    background: $white;
    width: 50px;
    height: 50px;

    svg {
      height: 20px;
      display: block;
      width: 100% !important;
      top: 50%;
      position: relative;
      transform: translateY(-50%);

      polygon {
        fill: $blue;
      }
    }

    &--next {
      right: auto;
      left: calc(60% - 50px);
      transform: rotate(-90deg);
    }

    &--previous {
      right: auto;
      left: calc(60% - 100px);
      transform: rotate(90deg);
      background: $white;
    }
  }

  .slick-list {
    overflow: visible;
  }
}

.sliderTwoSlide {
  height: 550px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.sliderTwoContent {
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  width: 60%;
  padding: 100px;
  padding-left: calc(50% - 640px);
  padding-right: 200px;
  background: alpha($blue, 0.95);
  z-index: 10000000;

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $white;
  }

  p {
    font-size: 18.7px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $white;
  }

  a {
    font-size: 14.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.7px;
    text-align: left;
    color: $yellow;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;

      path {
        fill: $yellow;
      }
    }

    span {
      margin-top: 1px;
    }
  }
}

.partnersSlider {
  margin-bottom: 100px;

  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: $blue;
  }

  h4 {
    max-width: 460px;
    font-size: 18.7px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.6px;
    text-align: left;
    color: $darkGray3;
  }

  .button {
    position: absolute;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    background: $white;
    width: 50px;
    height: 50px;

    svg {
      height: 20px;
      display: block;
      width: 100% !important;
      top: 50%;
      position: relative;
      transform: translateY(-50%);

      polygon {
        fill: $blue;
      }
    }

    &--next {
      right: 0;
      transform: rotate(-90deg);
    }

    &--previous {
      right: auto;
      left: 0;
      transform: rotate(90deg);
      background: $white;
    }
  }

  .moreButton {
    position: absolute;
    right: 60px;
    bottom: 0;

    a {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      transition: 300ms;

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }
    }

    .label {
      font-size: 14.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.07;
      letter-spacing: 0.7px;
      text-align: left;
      color: $blue;
      display: inline-block;
      margin-right: 40px;
      margin-top: 5px;
    }

    .arrow {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: $blue;
      display: inline-block;

      svg {
        width: 20px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
          fill: $white;
        }
      }
    }
  }
}

.partnersSliderWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.partnersSliderLeft,
.partnersSliderRight {
  width: 50%;
  position: relative;
}

.partnersSliderLeft {
  padding-left: 60px;
}

.partnersSliderRight {
  padding-right: 60px;
}

.partnersSliderFull {
  width: 100%;
  height: 170px;
  border-top: 1px solid $gray3;
  margin-top: 40px;
  padding-top: 60px;

  a {
    opacity: 0.6;
    transition: 300ms;

    &:hover {
      opacity: 1;
    }
  }
}

.partnersSliderFullSlide {
  height: 100px !important;

  a {
    display: block;
    height: 100px;
    text-align: center;
    vertical-align: middle;

    img {
      position: relative;
      vertical-align: middle;
      margin: 0 auto;
      height: 50px;
      width: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  img {
    position: relative;
    vertical-align: middle;
    margin: 0 auto;
    height: 50px;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

.topNewsBlock {
  width: 100%;
  margin-bottom: 100px;
}

.topNewsBlockWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.topNewsItem {
  width: 24.5%;
  margin-bottom: 30px;
  height: auto;
  text-align: center;
  background-color: $lightGray2;

  h5 {
    font-size: 19.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.98;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: $white;
    margin: 0;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 27.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: 1.5px;
    color: $white;
    margin: 0;
    margin-bottom: 90px;
    text-align: left;
  }

  h3 {
    font-size: 19.5px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.49;
    letter-spacing: 0.6px;
    text-align: center;
    color: $blue;
  }

  span {
    width: 60px;
    height: 60px;
    display: block;
    position: absolute;
    background: $white;
    border-radius: 100%;
    text-align: initial;
    bottom: 0;
    transition: 300ms;

    svg {
      width: 30px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: $darkBlue;
        opacity: 0.9;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.cardsItem {
  h2.cardsTitle {
    margin: 0;
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: 1.9px;
    text-align: left;
    color: #31349b;
  }

  .topNewsBlockWrap {
    justify-content: initial;
  }

  .topNewsItem {
    width: 24%;
    margin-right: 1%;

    .topNewsItemBottom {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-bottom: 30px;
    }

    h2 {
      font-size: 21px;
      margin-bottom: 30px;
    }

    span {
      position: relative;
    }

    h3 {
      margin: 0 0 0 10px;
      text-align: left;
    }

    p {
      font-size: 16.5px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      color: $white;
      margin: 0;
      text-align: left;
    }
  }
}

.topNewsItemCnt {
  padding: 20px 40px 90px 40px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 60px;
    left: 0;
    background-color: $darkBlue;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../images/top-news-bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom left;
    z-index: -1;
  }

  .removeMargin {
    margin-bottom: 0 !important;
  }

  .postImageWrap {
    width: 100%;
    height: 155px;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      width: 100%;
      height: 155px;
    }
  }
}

.topNewsItemBottom {
  padding: 0 30px;

  svg {
    width: 90px;
    position: relative;

    path {
      fill: $blue;
    }

    circle {
      fill: $blue;
    }
  }

  img {
    position: relative;
    vertical-align: middle;
    width: 90px;
  }
}

.sectionOneBlock {
  width: 100%;
  height: 590px;
  margin-bottom: 100px;

  &__Cnt {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  a.moreButton {
    display: block;
    position: relative;
    display: flex;
    transition: 300ms;
  }

  .label {
    font-size: 14.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.7px;
    text-align: left;
    color: $blue;
    display: inline-block;
    margin-left: 10px;
    margin-top: 2.5px;
  }

  .arrow {
    width: 20px;
    height: 20px;
    background: $white;
    display: inline-block;

    svg {
      width: 20px;
      position: relative;
      background: $lightGray;

      path {
        fill: $blue;
      }
    }
  }
}

.sectionOneWrap {
  width: 50%;
  position: relative;
}

.sectionOneImage {
  width: 100%;
  height: 590px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $blue;
    opacity: 0.3;
  }
}

.sectionOneCnt {
  height: 295px;
  background: $lightGray;
  width: calc(100% - 60px);
  position: relative;
  z-index: 1;
  padding: 70px 120px 0 80px;

  &::before {
    content: '';
    position: absolute;
    right: -60px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 295px 60px 0 0;
    border-color: $lightGray transparent transparent transparent;
  }

  h2 {
    font-size: 46.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 0.9px;
    text-align: left;
    color: $blue;
    margin: 0;
    margin-bottom: 10px;
  }
}

.sectionOneCntInverted {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 295px;
  background: $lightGray;
  width: calc(100% - 60px);
  z-index: 1;
  padding: 70px 80px 0 120px;

  &::before {
    content: '';
    position: absolute;
    left: -60px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 295px 60px;
    border-color: transparent transparent $lightGray transparent;
  }

  h2 {
    font-size: 46.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 0.9px;
    text-align: right;
    color: $blue;
    margin: 0;
    margin-bottom: 10px;
  }

  a.moreButton {
    position: absolute;
    right: 85px;
  }
}

.sectionTwoBlock {
  width: 100%;
  height: 590px;
  margin-bottom: 100px;

  &__Cnt {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  a.moreButton {
    display: block;
    position: relative;
    display: flex;
    transition: 300ms;
  }

  .label {
    font-size: 14.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: 0.7px;
    text-align: left;
    color: $blue;
    display: inline-block;
    margin-left: 10px;
    margin-top: 2.5px;
  }

  .arrow {
    width: 20px;
    height: 20px;
    background: $white;
    display: inline-block;

    svg {
      width: 20px;
      position: relative;
      background: $lightGray;

      path {
        fill: $blue;
      }
    }
  }
}

.sectionTwoWrap {
  width: 50%;
  position: relative;
}

.sectionTwoImage {
  width: 100%;
  height: 590px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $blue;
    opacity: 0.3;
  }
}

.sectionTwoCnt {
  height: 295px;
  background: $lightGray;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 70px 180px 0 80px;

  h2 {
    font-size: 46.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 0.9px;
    text-align: left;
    color: $blue;
    margin: 0;
    margin-bottom: 10px;
  }
}

.sectionTwoCntInverted {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 295px;
  background: $lightGray;
  width: 100%;
  z-index: 1;
  padding: 70px 80px 0 180px;

  h2 {
    font-size: 46.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: 0.9px;
    text-align: right;
    color: $blue;
    margin: 0;
    margin-bottom: 10px;
  }

  a.moreButton {
    position: absolute;
    right: 85px;
  }
}


.normalNewsBlock {
  width: 100%;
  margin-bottom: 100px;
}

.normalNewsBlockWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.normalNewsItem {
  width: 32%;
  margin-bottom: 70px;
  height: auto;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  position: relative;
  background-color: $lightGray2;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: -60px;
    left: 0;
    background-image: url('../images/top-news-bg.png');
    background-repeat: no-repeat;
    background-size: 70% auto;
    background-position: bottom left;
    z-index: -1;
  }

  h2 {
    font-size: 25.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: 1.5px;
    color: $blue;
    margin: 0;
    margin-bottom: 20px;
    text-align: left;
  }
}

.normalNewsItemCnt {
  padding: 20px 10% 0 10%;
  position: relative;

  img {
    max-width: 100%;
    margin: 0 auto;
  }
}

.normalNewsItemBottom {
  display: block;
  height: 150px;

  .moreButton {
    position: absolute;
    right: 10%;
    bottom: 60px;
    display: flex;
    text-align: left;

    a {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      transition: 300ms;

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }
    }

    .label {
      font-size: 14.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.07;
      letter-spacing: 0.7px;
      text-align: left;
      color: $blue;
      display: inline-block;
      margin-left: 20px;
      margin-top: 5px;
    }

    .arrow {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: $blue;
      display: inline-block;

      svg {
        width: 20px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
          fill: $white;
        }
      }
    }
  }
}

.servicesBlock {
  h2 {
    font-size: 46.5px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: .9px;
    text-align: center;
    color: $blue;
    margin: 0 auto;
    margin-bottom: 60px;
  }
}

.iconsList {
  max-width: 1180px;
  justify-content: center;
  margin: 0 auto;
}

.onlyIcon {
  width: 24.5%;
  transition: 300ms;
  cursor: pointer;
  background: transparent;

  img {
    width: 50px;
    cursor: pointer;
  }

  h3 {
    font-size: 18px;
    cursor: pointer;
  }

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

section {
  &:last-of-type {
    margin-bottom: 0 !important;
  }
}

.videoBlock {
  display: block;
  width: 100%;
  height: calc(100vw / 2);
  overflow: hidden;
  position: relative;
  margin-bottom: -50px;

  .overlayBg {
    top: -200px;
    left: -100px;
    z-index: 100000;

    ul {
      left: 150px;
      top: 250px;

      li {
        a {
          font-size: 15px;
        }
      }
    }
  }

  @include media('<=980px') {
    position: relative;
    height: auto;
    width: 100%;
    min-height: initial;
    margin-bottom: -40px;

    .overlayBg {
      position: relative;
      width: 100%;
      max-width: 100%;

      ul {
        padding: 2.5%;
        top: auto;
        left: auto;
        text-align: center;
        width: 100%;
        position: relative;

        li {
          display: inline-block;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 5px;
            bottom: 5px;
            right: -5px;
            width: 1px;
            background: $blue;
          }

          &:last-of-type {
            &::before {
              display: none;
            }
          }

          a {
            display: inline-block;
            color: $blue !important;
            padding: 0 5px;
          }
        }
      }
    }
  }
}

.videoContainer {
  position: absolute;
  height: calc(100vw / 2);
  width: 100%;
  overflow: hidden;

  @include media('<=980px') {
    position: relative;
    height: auto;
    width: 100%;
    min-height: initial;
  }

  video {
    min-width: 100%;
    min-height: 100%;
    margin-top: -40px;

    @include media('<=980px') {
      margin-top: auto;
      position: relative;
      height: auto;
      width: 100%;
      min-height: initial;
    }
  }
}

.teamBlock {
  &__Inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.teamBlockItem {
  width: 18%;
  margin-bottom: 5%;
  text-align: center;
  position: relative;
}

.teamBlockItemImg {
  width: 100%;
  height: 270px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
  }
}

.teamBlockItemCnt {
  text-align: center;

  h3 {
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: .6px;
    font-size: 18px;
    letter-spacing: .7px;
    color: $blue;
    margin: 0;
    margin-bottom: 10px;
  }

  h4 {
    margin: 0;
    font-weight: lighter;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: .6px;
    color: $darkGray3;
  }
}

.customControls {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: center;

  @include media('<=980px') {
    top: 20vh;
  }

  button {
    background: transparent;
    box-shadow: 0;
    border: 0;
    cursor: pointer;
    display: block;
    margin: 0 auto 10px auto;

    i {
      font-size: 40px;
      color: $white;

      @include media('<=980px') {
        font-size: 20px;
      }
    }

    &:last-of-type {
      i {
        font-size: 30px;

        @include media('<=980px') {
          font-size: 20px;
        }
      }
    }
  }
}

.parent-pageid-163,
.parent-pageid-145 {
  .pageContent li {
    color: $blue;
    font-weight: bold;
  }
}


#fileuploadfield,
.wpcf7-form-control-wrap.file-265 {
  display: none;
}

.uploadfieldtrick {
  width: 40%;
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-top: 5px;

  .uploadtextfield {
    width: 45% !important;

    .uploadtextfield {
      width: 100% !important;
    }
  }

  @include media('<=980px') {
    width: 100%;
    margin-bottom: 20px;
  }

  #uploadbrowsebutton {
    width: 46%;
    border-radius: 23px;
    background: $blue;
    border-color: transparent;
    font-size: 14.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 37px;
    letter-spacing: 0.7px;
    text-align: left;
    color: $white;
    text-transform: uppercase;
    font-family: $default;
    padding-left: 15px;
    background-image: url('../images/right-arrow.svg');
    background-position: 90% center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    cursor: pointer;
    transition: 300ms;

    &:hover {
      transform: scale(1.05)
    }

    @include media('<=980px') {
      width: 50%;
      font-size: 12px;
      background-size: 15px auto;
    }
  }

  #uploadtextfield {
    pointer-events: none;
    width: auto;
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.selectBox {

  #wpcf7-f822-p595-o1,
  #wpcf7-f823-p595-o2 {
    display: none;
  }
}