.siteFooterLanding {
  background: $darkBlue;
  width: 100%;
  height: 120px;
  text-align: center;
  span {
    font-size: 19.5px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.5px;
    color: $white;
    text-transform: uppercase;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
}
.siteFooter {
  width: 100%;
  background: $white;
  nav {
    height: 120px;
    ul {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }
    li {
      display: inline-block;
    }
    a {
      font-size: 14.5px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: $muse;
      padding: 0 10px;
    }
  }
}
.siteFooterNav {
  position: relative;
  height: 120px;
}
.siteFooterCopy {
  height: 100px;
  width: 100%;
  background: $blue;
  ul {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  li {
    display: inline-block;
    font-size: 13.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: $white;
    padding: 0 20px;
    span {
      font-weight: bold;
    }
  }
  li.socialNetwork {
    padding: 0;
    a {
      padding: 0 10px;
      font-size: 16px;
    }
  }
  a {
    font-size: 13.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: $white;
    padding: 0 20px;
  }
}
.goTopButton {
  height: 45px;
  width: 45px;
  display: block;
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  background: $blue;
  border-radius: 100%;
  transition:300ms;
  &:hover {
    transform: translateY(-50%) scale(1.05);
  }
  svg {
    max-width: 20px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(90deg);
    path {
      fill: $white;
    }
  }
}