.landingButton {
  position: absolute;
  width: 270px;
  height: 230px;
  background-image: url('../images/landing-button-bg.png');
  display: block;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  left: 52.5%;
  transform:translateX(-50%);
  top: calc(100% - 210px);
  z-index: 100;
  transition:300ms;
  &:hover {
    transform: translateX(-50%) scale(1.05);
  }
  svg {
    transform: translate(-50%,-50%) rotate(-90deg);
    max-width: 55px;
    max-height: 40px;
    position: relative;
    top: 55%;
    left: 50%;
    polygon {
      fill: $white;
    }
  }
}
.business {
  width: 100%;
  height: calc(50vh - 120px);
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.businessLeft {
  width: 45%;
  padding-left: calc(50% - 640px);
  height: calc(50vh - 120px);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    right: -350px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(50vh - 119px) 0 0  350px;
    border-color: transparent transparent transparent $white;
  }
}
.businessRight {
  width: 55%;
  height: calc(50vh - 120px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}


.private {
  width: 100%;
  height: calc(50vh - 120px);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .landingButton {
    background-image: url('../images/landing-button-bg-2.png');
    left: 47.5%;
    svg {
      transform: translate(-50%,-50%) rotate(90deg);
    }
  }
}
.privateLeft {
  width: 55%;
  height: calc(50vh - 120px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.privateRight {
  width: 45%;
  padding-right: calc(50% - 640px);
  text-align: right;
  height: calc(50vh - 120px);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    left: -350px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 calc(50vh - 120px) 350px;
    border-color: transparent transparent $white 
  }
}
.landing {
  h2 {
    font-size: 34px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.37;
    letter-spacing: 1.7px;
    color: $blue;
    margin: 0;
    span {
      display: block;
      font-size: 46.5px;
      font-weight: bold;
      letter-spacing: 2.3px;
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: $gray;
  }
  .landingWrap {
    top: 50%;
    transform:translateY(-50%);
    position: relative;
  }
}